import * as Sentry from '@sentry/react';

import config from '../config';
import { Dokument } from '../domain/Dokument';

export const HttpStatus = {
  BadRequest: 400,
  UnprocessableEntity: 422,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  Created: 201,
  GatewayTimeout: 504,
  Unavailable: 503,
};

export async function fetchGet(url: string): Promise<any> {
  const response = await fetch(url, {
    method: 'GET',
    headers: defaultAuthHeaders(),
  });

  return handleResponse(response, url);
}

export async function fetchGetUnauthorized(url: string): Promise<any> {
  const response = await fetch(url, {
    method: 'GET',
  });

  return handleResponse(response, url);
}

export async function fetchPostPlainBody<T>(url: string, body: T): Promise<any> {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      ...defaultAuthHeaders(),
      'Content-Type': 'application/json',
      'Accept-Language': 'no-NB',
    },
    body: JSON.stringify(body),
  });

  return handleResponse(response, url);
}

export async function fetchPost<T>(url: string, body?: T): Promise<any> {
  const bodySend = body
    ? Object.keys(body)
        // @ts-ignore
        .filter((k) => body[k] != null && body[k] !== '')
        // @ts-ignore
        .reduce((a, k) => ({ ...a, [k]: body[k] }), {})
    : undefined;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      ...defaultAuthHeaders(),
      'Content-Type': 'application/json',
      'Accept-Language': 'no-NB',
    },
    body: bodySend ? JSON.stringify(bodySend) : undefined,
  });

  return handleResponse(response, url);
}

export async function fetchPostForm(url: string, body?: FormData): Promise<any> {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      ...defaultAuthHeaders(),
      'Accept-Language': 'no-NB',
    },
    body: body ? body : undefined,
  });

  return handleResponse(response, url);
}

export async function fetchDelete(url: string, body?: any): Promise<any> {
  const bodySend = body
    ? Object.keys(body)
        // @ts-ignore
        .filter((k) => body[k] != null && body[k] !== '')
        // @ts-ignore
        .reduce((a, k) => ({ ...a, [k]: body[k] }), {})
    : undefined;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      ...defaultAuthHeaders(),
      'Content-Type': 'application/json',
      'Accept-Language': 'no-NB',
    },
    body: bodySend ? JSON.stringify(bodySend) : undefined,
  });

  return handleResponse(response, url);
}

export async function fetchPutPlainBody<T>(url: string, body: T): Promise<any> {
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...defaultAuthHeaders(),
      'Content-Type': 'application/json',
      'Accept-Language': 'no-NB',
    },
    body: JSON.stringify(body),
  });

  return handleResponse(response, url);
}

export async function fetchPut<T>(url: string, body?: T): Promise<any> {
  const bodySend = body
    ? Object.keys(body)
        // @ts-ignore
        .filter((k) => body[k] != null && body[k] !== '')
        // @ts-ignore
        .reduce((a, k) => ({ ...a, [k]: body[k] }), {})
    : undefined;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...defaultAuthHeaders(),
      'Content-Type': 'application/json',
      'Accept-Language': 'no-NB',
    },
    body: bodySend ? JSON.stringify(bodySend) : undefined,
  });

  return handleResponse(response, url);
}

export async function putFile(url: string, file: File, xHeaders: {}): Promise<any> {
  const upload = await putDocumentFileUrl(url, xHeaders, file);
  const response = await fetch(upload.uploadUrl, {
    method: 'PUT',
    headers: {
      ...defaultAuthHeaders(),
      ...xHeaders,
    },
    body: file,
  });
  if (response.ok) {
    const headResponse = await fetch(upload.headUrl, {
      method: 'HEAD',
      headers: {
        ...defaultAuthHeaders(),
        ...xHeaders,
      },
    });

    if (headResponse.ok) {
      return handleResponse(upload.response, url, file);
    } else {
      return handleResponse(headResponse, url, file);
    }
  } else {
    await fetch(`/api${upload.deleteUrl}`, {
      method: 'DELETE',
      headers: {
        ...defaultAuthHeaders(),
        ...xHeaders,
      },
    });

    return handleResponse(response, url, file);
  }
}

export async function putDocumentFileUrl(
  url: string,
  xHeaders: {},
  file: File
): Promise<{ response: Response; uploadUrl: string; headUrl: string; deleteUrl: string }> {
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...defaultAuthHeaders(),
      ...xHeaders,
      // @ts-ignore
      'x-content-length-upload': xHeaders['content-length'],
    },
  });

  if (response.status === 202 && response.headers.has('location')) {
    return Promise.resolve({
      response: response,
      uploadUrl: response.headers.get('location')!,
      headUrl: response.headers.get('x-head-url')!,
      deleteUrl: response.headers.get('x-delete-url')!,
    });
  } else {
    return handleResponse(response, url, file);
  }
}

export async function putDocumentFolder(url: string, xHeaders: {}): Promise<Dokument> {
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...defaultAuthHeaders(),
      ...xHeaders,
      // @ts-ignore
      'x-content-length-upload': xHeaders['content-length'],
    },
  });
  return handleResponse(response, url);
}

export async function deleteFile(url: string, xHeaders?: {}): Promise<any> {
  const deleteUrl = await deleteFileUrl(url, xHeaders);
  const response = await fetch(deleteUrl, {
    method: 'DELETE',
    headers: {
      ...defaultAuthHeaders(),
      ...xHeaders,
    },
  });

  return handleResponse(response, url);
}

async function deleteFileUrl(url: string, xHeaders?: {}): Promise<string> {
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      ...defaultAuthHeaders(),
      ...xHeaders,
    },
  });

  if ((response.status === 202 || response.status === 204) && response.headers.has('location')) {
    return Promise.resolve(response.headers.get('location')!);
  } else {
    return handleResponse(response, url);
  }
}

async function handleResponse(response: Response, url: string, file?: File) {
  if (response.ok) {
    return response.json().catch((_) => Promise.resolve());
  }
  if (file) {
    const fileUploadErrorInfo = {
      error: await getErrorResponse(response),
      file: file,
    };
    return Promise.reject(fileUploadErrorInfo);
  }

  if (response.status === HttpStatus.Unauthorized) {
    if (!window.location.pathname.includes('/login')) {
      sessionStorage.setItem(
        'session_redirect_uri',
        window.location.href.replace(window.location.origin, '')
      );
    }
    if (config.stage === 'local') {
      window.location.href = config.loginUri;
    } else {
      window.location.href = `https://${window.location.hostname}/secure/loggedIn`;
    }
    return Promise.reject();
  }
  if (response.status === HttpStatus.NotFound) {
    return Promise.reject(response.status);
  }
  const error = await getErrorResponse(response);
  if (response.status === HttpStatus.BadRequest) {
    console.error(`Bad request on ${url}:`, error);
    return Promise.reject(error);
  }
  if (response.status === HttpStatus.UnprocessableEntity) {
    return Promise.reject(error);
  }
  if (response.status === HttpStatus.Forbidden) {
    console.error(`Forbidden request on ${url}:`, error);
    throw Error(`Access Denied at url: ${url}`);
  }
  if (response.status === HttpStatus.GatewayTimeout) {
    return Promise.reject(response.status);
  }
  if (response.status === HttpStatus.Unavailable) {
    return Promise.reject(response.status);
  }

  throw Error('Something went wrong on the server');
}

function getErrorResponse(response: Response): Promise<any> {
  try {
    return response.json();
  } catch (ex) {
    try {
      return response.text();
    } catch (ex) {
      return Promise.resolve(null);
    }
  }
}

const localStorageAvailable = () => {
  try {
    const x = '__storage_test__';
    localStorage.setItem(x, x);
    localStorage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
};

export function defaultAuthHeaders() {
  if (localStorageAvailable()) {
    const accessToken = localStorage.getItem('jwt-accesstoken');
    const identity = localStorage.getItem('jwt-identity');
    const data = localStorage.getItem('jwt-data');
    const assumeId = localStorage.getItem('assumeId');
    const assumeSelskapId = localStorage.getItem('assumeSelskapId');

    return Object.assign(
      {},
      accessToken === null ? null : { 'jwt-accesstoken': accessToken },
      identity === null ? null : { 'jwt-identity': identity },
      data === null ? null : { 'jwt-data': data },
      assumeId === null ? null : { 'x-assume-id': assumeId },
      assumeSelskapId === null ? null : { 'x-assume-selskap': assumeSelskapId }
    );
  } else {
    return;
  }
}

const chunkSize = 64 * 1024 * 1024;
const fileReader = new FileReader();
let hasher: any;

export async function hashFile(file: File): Promise<string> {
  if (hasher) {
    hasher.init();
  } else {
    hasher = await import('hash-wasm').then((hash) => hash.createMD5());
  }

  const chunkNumber = Math.floor(file.size / chunkSize);

  for (let i = 0; i <= chunkNumber; i++) {
    const chunk = file.slice(chunkSize * i, Math.min(chunkSize * (i + 1), file.size));
    await hashChunk(chunk);
  }

  const hash = hasher.digest('binary');
  const base64 = await import('buffer').then((buffer) => {
    return Promise.resolve(buffer.Buffer.from(hash).toString('base64'));
  });

  return Promise.resolve(base64);
}

function hashChunk(chunk: Blob) {
  return new Promise<void>((resolve, _) => {
    fileReader.onload = async (e) => {
      // @ts-ignore
      const view = new Uint8Array(e.target.result);
      hasher!!.update(view);
      resolve();
    };

    fileReader.readAsArrayBuffer(chunk);
  });
}

// @ts-ignore
window.addEventListener('error', Sentry.captureEvent, true);

if (process.env.REACT_APP_STAGE === 'prod') {
  console.error = (err: Error) => Sentry.captureException(err);
}
