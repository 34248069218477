import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useScreenBreakpoints } from '../utils/useScreenBreakpoints';

import { NotificationContainer } from '../components/notifications/NotificationContainer';

const defaultOptions = {
  autoClose: true,
  hideProgressBar: true,
  closeButton: false,
  // This will close the notification if you click it anywhere
  // If set to false, the close icon will still close the notification
  closeOnClick: true,
  style: { background: 'none', boxShadow: 'none', padding: 0 },
} as any;

export const useNotifications = () => {
  const { t } = useTranslation();
  const { isLargeScreen } = useScreenBreakpoints();

  const notify = (
    type: 'info' | 'success' | 'error' | 'loading',
    title?: string,
    description?: string,
    options?: any
  ) =>
    toast(
      ({ closeToast }) => (
        <NotificationContainer
          closeToast={closeToast}
          type={type}
          title={title || t('global.somethingWentWrong')}
          description={description || ''}
        />
      ),
      { ...defaultOptions, ...options, position: isLargeScreen ? 'top-right' : 'top-center' }
    );

  return {
    info: (title?: string, description?: string, options?: any) =>
      notify('info', title, description, options),
    success: (title?: string, description?: string, options?: any) =>
      notify('success', title, description, options),
    loading: (title?: string, description?: string, options?: any) => {
      return notify('loading', title, description, {
        autoClose: false,
        hideProgressBar: false,
        ...options,
      });
    },
    error: (error?: any, title?: string, description?: string, options?: any) => {
      if (error) console.error(error);

      notify('error', title, description, options);
    },
    genericError: (error: any, options?: any): string | number => {
      console.error(error);

      return notify('error', t('global.somethingWentWrong'), t('global.tryAgainLater'), options);
    },
    dismiss: (id: string | number) => toast.dismiss(id),
  };
};
